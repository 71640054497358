<template>
    <div class="main" style="text-align: center; padding:40px;">


        <h3>Welcome!</h3>

        <p>Congratulations, you finally arrived! steffisecret </p>


        <p>
            <a href="/register">Sign up</a> or <a href="/login">Sign in</a>
        </p>


    </div>
</template>

<script>
    export default {
    props: []
    }
</script>